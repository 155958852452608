<template>
  <div class="bg-gray-300 h-screen">
    <!-- <w-top-bar>Eventos</w-top-bar> -->

    <!-- Main modal -->
    <div
      data-modal-backdrop="static"
      v-if="modal"
      tabindex="10000"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div
          class="relative bg-white rounded-lg shadow dark:bg-gray-700 h-screen"
        >
          <!-- Modal header -->
          <div
            class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600"
          >
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              Eventos
            </h3>
            <button
              type="button"
              @click="modal = false"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="staticModal"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <label
              for="years"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Selecciona un Evento</label
            >
            <select
              id="years"
              style="min-height: 200px"
              size="5"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option>2016</option>
              <option>2017</option>
              <option>2018</option>
              <option>2019</option>

              <option>2021</option>
              <option>2022</option>
            </select>
          </div>
          <!-- Modal footer -->
          <div
            class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 absolute bottom-0"
          >
            <button
              data-modal-hide="staticModal"
              type="button"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Aceptar
            </button>
            <button
              @click="modal = false"
              data-modal-hide="staticModal"
              type="button"
              class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-300 rounded text-center mt-6 mx-4">
      <!-- <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Eventos</label> -->
      <select
        id="countries"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        <option selected>Seleccionar Evento</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>
    </div>

    <div
      class="border-2 w-90 h-90 mx-4 border-black m-auto d-block border-dashed mt-8 flex justify-center items-center scaner-css bg-white"
    >
      <div class="w-100 m-auto my-8" v-if="loader">
        <span class="loader text-center m-auto"></span>
      </div>
      <!-- <img v-if="imgQr" src="@/assets/qr-img.png" class="my-12" style="width:200px" alt=""> -->
      <div class="bg-transparent rounded-lg" v-if="scaner">
        <!-- <h1 class="text-2xl font-semibold mb-4">Escanear QR de Entrada</h1> -->
        <div class="">
          <!-- <button @click="scaner = true">Escanear QR</button> -->
          <QrScanerEntrada :flashActive="flash" />
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center bg-gray-300">
      <!-- <div class="bg-white p-6 rounded-lg shadow-md">
                                                                    <h1 class="text-2xl font-semibold mb-4">Escanear QR de Entrada</h1>
                                                                    <div class="border-2 border-gray-300 p-4 rounded-lg">
                                                                        <button @click="scaner = true">Escanear QR</button>
                                                                        <QrScanerEntrada v-if="scaner" />
                                                                    </div>
                                                                </div>
                                                     -->
    </div>
    <div class="bg-white w-100 rounded-lg menu-responsive">
      <div class="p-2 m-2 bg-white">
        <div class="mx-2 bg-gray-300 rounded-lg">
          <div class="flex">
            <!-- <div v-if="scaner" @click="scanOff()" class="w-1/3  p-2 text-center flex items-center justify-center">
                                                                
                                                                <svg class="mt-1 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24"><path d="M14 9l-2.519 4-2.481-1.96-5 6.96h16l-6-9zm8-5v16h-20v-16h20zm2-2h-24v20h24v-20zm-20 6c0-1.104.896-2 2-2s2 .896 2 2c0 1.105-.896 2-2 2s-2-.895-2-2z"/></svg>
                                                            </div> -->

            <div class="w-1/3 p-2 text-center flex items-center justify-center">
              <!-- Contenido de la segunda columna -->

              <svg
                @click="modal = true"
                class="m-auto cursor-pointer"
                clip-rule="evenodd"
                fill-rule="evenodd"
                width="42"
                height="42"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm4.998 13.245c0-.414-.336-.75-.75-.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75zm0-3.248c0-.414-.336-.75-.75-.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75zm0-3.252c0-.414-.336-.75-.75-.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75z"
                  fill-rule="nonzero"
                />
              </svg>
            </div>
            <div class="w-1/3 p-2 text-center flex items-center justify-center">
              <!-- Contenido de la primera columna -->
              <svg
                v-if="!scaner"
                @click="scanOn()"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
              >
                <path
                  d="M5 4h-3v-1h3v1zm10.93 0l.812 1.219c.743 1.115 1.987 1.781 3.328 1.781h1.93v13h-20v-13h3.93c1.341 0 2.585-.666 3.328-1.781l.812-1.219h5.86zm1.07-2h-8l-1.406 2.109c-.371.557-.995.891-1.664.891h-5.93v17h24v-17h-3.93c-.669 0-1.293-.334-1.664-.891l-1.406-2.109zm-11 8c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm7 0c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.761 0-5 2.239-5 5s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5z"
                />
              </svg>
              <!-- <svg @click="scanOn()" class="mt-1 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24"><path d="M14 9l-2.519 4-2.481-1.96-5 6.96h16l-6-9zm8-5v16h-20v-16h20zm2-2h-24v20h24v-20zm-20 6c0-1.104.896-2 2-2s2 .896 2 2c0 1.105-.896 2-2 2s-2-.895-2-2z"/></svg> -->
              <svg
                v-if="scaner"
                @click="scanOff()"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
              >
                <path
                  d="M5 4h-3v-1h3v1zm8 6c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-5v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm13 4c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z"
                />
              </svg>
            </div>
            <div class="w-1/3 p-2 text-center flex items-center justify-center">
              <!-- Contenido de la tercera columna -->
              <div class="mt-1">
                <svg
                  v-if="!flash"
                  @click="activateFlash()"
                  class="m-auto cursor-pointer"
                  width="42"
                  height="42"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M13 9h9l-14 15 3-9h-9l14-15-3 9zm-8.699 5h8.086l-1.987 5.963 9.299-9.963h-8.086l1.987-5.963-9.299 9.963z"
                  />
                </svg>

                <svg
                  v-if="flash"
                  @click="desactivateFlash()"
                  class="m-auto cursor-pointer"
                  width="42"
                  height="42"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M8 24l3-9h-9l14-15-3 9h9l-14 15z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrScanerEntrada from "@/components/QrScanerEntrada.vue";
// import { makeFindMixin } from "feathers-vuex";
// import { mapActions } from "vuex";

export default {
  name: "inscriptionsList",
  layout: "entrada",
  data() {
    return {
      pastEvents: false,
      scaner: false,
      flash: false,
      loader: false,
      modal: false,
      imgQr: true,
    };
  },
  components: {
    QrScanerEntrada,
  },
  methods: {
    activateFlash() {
      //solo si esta activo el scaner
      if (this.scaner) {
        this.flash = true;
      } else {
        alert("Debes activar la Camara");
      }
    },
    desactivateFlash() {
      //solo si
      if (this.scaner) {
        this.flash = false;
      } else {
        alert("Debes activar la Camara");
      }
    },
    scanOn() {
      this.imgQr = false;
      this.loader = true;
      setTimeout(() => {
        this.loader = false;
        this.scaner = true;
      }, 1000);
    },
    scanOff() {
      this.scaner = false;
      this.loader = true;
      setTimeout(() => {
        this.loader = false;
        this.imgQr = true;
      }, 1000);
    },
  },
  // mixins: [makeFindMixin({ service: "products", name: "events" })],
  computed: {
    // eventsParams() {
    //   let past = {};
    //   // if (!this.pastEvents)
    //   //   past = {
    //   //     dateTS: {
    //   //       $gt: new Date().getTime(),
    //   //     },
    //   //   };
    //   return {
    //     query: {
    //       type: "streamings",
    //       subproduct: { $ne: true },
    //       $sort: {
    //         date: -1,
    //       },
    //       ...past,
    //     },
    //   };
    // },
  },
};
</script>

<style lang="scss" scoped>
.menu-responsive {
  position: fixed;
  bottom: 5%;
  width: 90%;
  margin: auto;
  left: 5%;
  right: 5%;
}

.scaner-css {
  min-height: 320px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.scaner {
  animation: myAnimjjj 1s ease 0s 1 reverse backwards;
}

@keyframes myAnimjjj {
  0% {
    opacity: 0;
    transform: rotate(-540deg) scale(0);
  }
  100% {
    opacity: 1;
    transform: rotate(0) scale(1);
  }
}

svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

svg text::selection {
  background: none;
}
</style>
