<template>
  <div>
    <p class="error">{{ error }}</p>
    <p v-if="torchNotSupported" class="error">Torch not supported</p>
    <audio id="audio-player" class="d-none">
      <!-- <source src="../assets/bep.mp3" type="audio/mpeg"> -->
      Your browser does not support the audio tag.
    </audio>
    <qrcode-stream
      :torch="flashActive"
      @decode="onDecode"
      @init="onInit"
      class="qr-scanner-app"
    />
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { QrcodeStream },

  data() {
    return {
      result: "",
      error: "",
      torchActive: false,
      torchNotSupported: false,
    };
  },
  props: {
    flashActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onDecode(result) {
      this.result = result;
      console.log("result", result);
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },

    toggleAudio() {
      var audio = document.getElementById("audio-player");
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    },
  },
  watch: {
    result: function (val) {
      var parsedData = JSON.parse(val);
      var eventId = parsedData.eventId;
      // console.log(eventId);
      //error aca pasa mal el id pero funciona sale como undefined
      this.$router.push({
        path: "/scan-entrada/" + eventId,
      });
    },
  },
};
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
.qr-scanner-app {
  height: 330px;
}
</style>
